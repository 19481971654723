export const Header = (props) => {
  return (
    <>
      <div id="img-carousel" data-ride="carousel" className="carousel slide">
        <ol className="carousel-indicators">
          <li
            data-target="#img-carousel"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#img-carousel" data-slide-to="1"></li>
          <li data-target="#img-carousel" data-slide-to="2"></li>
        </ol>

        <div role="listbox" className="carousel-inner">
          <div className="item active">
            <div className="carousel-caption">
              <h2 className="animated fadeInDown">
                We Provides Best Services with Priority.
              </h2>
              <p className="animated fadeInUp">
                <a href="#">
                  software development & support services for your business
                </a>
              </p>
            </div>
          </div>
          <div className="item">
            <div className="carousel-caption">
              <h2 className="animated bounceInDown">
                Best Ecommerce Web/Mobile App Development
              </h2>
              <p className="animated bounceInUp">
                <a href="#">Industry-Experienced Developers, On Demand</a>
              </p>
            </div>
          </div>
          <div className="item">
            <div className="carousel-caption">
              <h2 className="animated fadeInDown">User Friendly Experience</h2>
              <p className="animated fadeInRight">
                <a href="#">Software Development Done Right away</a>
              </p>
            </div>
          </div>
        </div>
        <a
          href="#img-carousel"
          role="button"
          data-slide="prev"
          className="left carousel-control"
        >
          <i aria-hidden="true" className="fa fa-chevron-left"></i>
          <span className="sr-only">Previous</span>
        </a>
        <a
          href="#img-carousel"
          role="button"
          data-slide="next"
          className="right carousel-control"
        >
          <i aria-hidden="true" className="fa fa-chevron-right"></i>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </>
  );
};
